import styles from './home.module.scss';
import Button from '@/components/Button';
import SEOHelmet from '@/components/SEOHelmet';
import EmailLink from '@/components/EmailLink';
import { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const Home = () => {
  const tabs = [
    {
      title: 'Virtual Assistant',
      description: (
        <>
          <p>
            The GANNET Virtual Assistant provides instant access to verified,
            actionable information. A Generative-AI chatbot, it transforms
            complex data into clear, actionable information, enabling faster
            crisis response and better-informed decision-making.
          </p>
          <p>
            With features like multi-lingual support and secure encryption, It
            serves as your round-the-clock analysis team, helping humanitarian
            organizations maximize their impact while reducing costs.
          </p>
        </>
      ),
      demo: '/gannet-virtual-assistant-demo.mp4',
      cta: (
        <p>
          <Button
            variation="alt"
            className={styles['sign-up-btn']}
            onClick={() => {
              window.open('https://app.gannet.ai/sign-up', '_blank');
            }}
          >
            SIGN UP
          </Button>
          or{' '}
          <a href="https://app.gannet.ai/sign-in" target="_blank">
            sign in
          </a>{' '}
          if you already have an account.
        </p>
      ),
    },
    {
      title: 'SituationHub',
      description: (
        <>
          <p>
            The GANNET SituationHub is a GenAI-powered data analysis platform
            that is changing how humanitarian organizations handle humanitarian
            analysis. It turns complex information into clear insights, enabling
            faster and more effective humanitarian response.
          </p>
          <p>
            The platform streamlines humanitarian data analysis to make it
            easier for organizations to make informed decisions and improve
            impact.
          </p>
          <p>Here are some of the crises we are currently monitoring:</p>
        </>
      ),
      demo: '/gannet-sithub-demo.mp4',
      cta: (
        <div className={styles['crisis-btns']}>
          {[
            { name: 'SUDAN', slug: 'sudan' },
            { name: 'LEBANON', slug: 'lebanon' },
            { name: 'MYANMAR', slug: 'myanmar' },
          ].map(({ name, slug }, index) => (
            <>
              <Button
                key={index}
                variation="alt"
                onClick={() => {
                  window.open(`https://analysis.gannet.ai/${slug}`, '_blank');
                }}
              >
                {name}
              </Button>
              {index < 2 && <span></span>}
            </>
          ))}
        </div>
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const heroImageRef = useRef(null);
  const heroTextRef = useRef(null);
  const tabRefs = useRef<Array<HTMLDivElement | null>>([]);
  const animationRef = useRef(false);

  useEffect(() => {
    if (!animationRef.current && heroImageRef.current && heroTextRef.current) {
      animationRef.current = true;
      gsap.from(heroImageRef.current, {
        x: window.innerWidth,
        opacity: 0,
        duration: 1,
        ease: 'power3.out',
      });
      gsap.from(heroTextRef.current, {
        opacity: 0,
        duration: 1,
        delay: 0.5,
        ease: 'power3.out',
      });
    }
  }, []);

  useEffect(() => {
    if (tabRefs.current[activeTab]) {
      gsap.fromTo(
        tabRefs.current[activeTab],
        {
          x: activeTab === 0 ? -window.innerWidth : window.innerWidth,
          opacity: 0.4,
        },
        { x: 0, opacity: 1, duration: 1, ease: 'power3.out' }
      );
    }
  }, [activeTab]);

  return (
    <article>
      <SEOHelmet
        title="GANNET"
        description="GANNET is a Generative AI Initiative, offering AI-powered tools built to make knowledge and real-time information accessible to our community in a resource-efficient and ethical way."
        image={`${window.location.origin}/gannet-green.png`}
      />
      <div className={styles['home-content']}>
        <div className={styles['hero']}>
          <div className={styles['hero__header']}>
            <img
              className={styles['logo']}
              src="/gannet-green.png"
              height="100"
              alt="Gannet green logo"
            />
            <h1>AI FOR HUMANITARIANS, BY HUMANITARIANS</h1>
          </div>
          <div className={styles['hero__content']}>
            <div className={styles['hero__content__body']} ref={heroTextRef}>
              <p className={styles['headline']}>
                GANNET is a Generative AI Initiative, offering AI-powered tools
                built to make knowledge and real-time information accessible to
                our community in a resource-efficient and ethical way.
              </p>

              <p>
                Interested in partnering with us?{' '}
                <EmailLink text="Contact Us" />.
              </p>
              <div className={styles['brand']}>
                By
                <a href="https://datafriendlyspace.org" target="_blank">
                  <img src="./dfs-logo.svg" alt="Data Friendly Space" />
                </a>
                <Button
                  className={styles['support-btn']}
                  variation="secondary"
                  onClick={() => {
                    window.open('https://givebutter.com/gannet', '_blank');
                  }}
                >
                  SUPPORT GANNET
                  <span className={styles['support-btn__rocket']}>🚀</span>
                </Button>
              </div>
            </div>

            <div className={styles['hero__content__media']}>
              <img
                ref={heroImageRef}
                className={styles['hero-img']}
                src="/gannet-hero.png"
                alt="Gannet Hero"
              />
            </div>
          </div>
        </div>

        <h2>OUR PRODUCTS</h2>
        <section className={styles['tabs']}>
          <div className={styles['tabs__controllers']}>
            {tabs.map((tab, index) => (
              <button
                key={index}
                type="button"
                onClick={() => setActiveTab(index)}
                className={activeTab === index ? styles['active'] : ''}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div className={styles['tabs__content']}>
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={styles['tabs__content__body']}
                data-tab={index}
                data-active={activeTab === index}
                ref={(el) => (tabRefs.current[index] = el)}
              >
                <video autoPlay loop muted>
                  <source src={tab.demo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <div>
                  <h3>{tabs[activeTab].title}</h3>
                  {tabs[activeTab].description}
                  {tabs[activeTab].cta}
                </div>
              </div>
            ))}
          </div>
        </section>
        <footer>© 2025 DFS. All rights reserved</footer>
      </div>
    </article>
  );
};

export default Home;
