import { classnames } from '@/utils/functions';
import styles from './email-link.module.scss';
import { FC } from 'react';
import useSession from '@/hooks/useSession';

interface EmailLinkProps {
  text?: string;
  className?: string;
}

const CONTACT_EMAIL = 'info@gannet.ai';

const EmailLink: FC<EmailLinkProps> = ({ text = CONTACT_EMAIL, className }) => {
  const { pushUserEvent } = useSession();
  return (
    <a
      className={classnames(styles['email-link'], {
        [className as string]: !!className,
      })}
      href={`mailto:${CONTACT_EMAIL}`}
      onClick={() => {
        pushUserEvent('click_contact_email');
      }}
    >
      {text}
    </a>
  );
};

export default EmailLink;
